import {
    onBeforeUnmount,
    onMounted,
    reactive,
    toRefs,
} from '@vue/composition-api';
import store from '@/store';

let timer: number | undefined = undefined;
export default {
    props: {
        msg: String,
    },
    setup() {
        onMounted(() => {
            let i = 0;
            const emojiArr = ['😰', '😨'];
            timer = setInterval(() => {
                i++;
                if (i >= emojiArr.length) {
                    i = 0;
                }
                store.commit('changeMetaInfo', {
                    title: `${emojiArr[i]}程!序!崩!溃!`,
                });
            }, 90);
        });
        onBeforeUnmount(() => {
            clearInterval(timer);
        });
        //console.log(this.props.deniedMsg);
        const getKey = (): string => {
            return Number(
                new Date().getTime() + '' + Math.round(Math.random() * 99999999)
            ).toString(32);
        };
        const data = reactive({
            key: getKey(),
            programErrorList: [
                '您在生产环境下开启了控制台(开发人员工具)',
                '组件文件或结构树异常，请检查是否被篡改',
                '程序核心文件损坏，请重新打包编译',
                '后端接口错误，请后端程序员提供正确且符合规范的API',
                '当前请求次数过多，导致服务器超负载运行',
                '您使用了不兼容的浏览器/客户端（请使用IE11及以上版本的浏览器）',
                '其他导致系统错误的原因',
            ],
            reloadText: '您可点击此处刷新页面重试，或按键盘Ctrl + F5强制刷新',
            questionText: '若问题仍无法解决，请与站长联系',
        });
        return { ...toRefs(data) };
    },
};
